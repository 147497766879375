<template>
  <div class="copyright-copyright"
    ><div class="copyright-max-width max-content-container"
      ><span class="copyright-text"
        >© All rights reserved Rolly Arcanetify</span
      ></div
    ></div
  >
</template>

<script>
export default {
  name: 'Copyright',
  props: {},
}
</script>

<style scoped>
.copyright-copyright {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.copyright-max-width {
  align-items: stretch;
}
.copyright-text {
  color: var(--dl-color-grays-gray100);
}
@media(max-width: 991px) {
  .copyright-max-width {
    flex-direction: column;
  }
}
</style>
