<template>
  <div class="home-container">
  <div class="top-banner">
  <p>Get Rolly now and unlock White Label for FREE! Limited time only!</p>
</div>
    <app-navbar></app-navbar>
    <main class="home-main">
      <div class="section-container">
        <div class="home-max-width max-content-container">
          <div class="home-content-container">
            <h1 class="home-text Heading1">
              <span>Support Your Small Business with</span>
              <span class="home-text02"> Rolly </span>
              <span>— Free Tools for Most of Your Needs!</span>
            </h1>
            <a href="https://www.producthunt.com/posts/rolly-free-app-for-small-business?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-rolly&#0045;free&#0045;app&#0045;for&#0045;small&#0045;business" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=462377&theme=light" alt="Rolly&#0032;&#0045;&#0032;Free&#0032;App&#0032;for&#0032;Small&#0032;Business - Custom&#0032;quotation&#0044;&#0032;invoices&#0032;&#0038;&#0032;receipts&#0032;creator | Product Hunt" style="width: 250px; height: 54px; margin-bottom:20px;" width="250" height="54" /></a>

            <div class="home-input-container">
              <a
                href="https://apps.apple.com/gd/app/rolly-custom-invoice-creator/id6479781630?platform=iphone"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link"
                ><img
                  alt="image"
                  src="/download/appstore-200h.webp"
                  class="home-image" /></a
              ><a
                href="https://play.google.com/store/apps/details?id=com.arcanetify.justinvoice"
                target="_blank"
                rel="noreferrer noopener"
                class="home-link1"
                ><img
                  alt="image"
                  src="/download/googleplay-200h.webp"
                  class="home-image1"
              /></a>
              
              
            </div>

            
            <div class="home-features-container">
              <div class="home-feature">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon">
                  <path
                    d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path></svg
                ><span class="home-text04"
                  >Generate estimates/quotations, invoices, and receipts</span
                >
              </div>
              <div class="home-feature1">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon2">
                  <path
                    d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path></svg
                ><span class="home-text05"
                  >Fully customizable with a wide range of background selections
                  for PDF output</span
                >
              </div>
              <div class="home-feature2">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon4">
                  <path
                    d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path></svg
                ><span class="home-text06"
                  >Supports generating invoices in over 18 different
                  languages</span
                >
              </div>
              <div class="home-feature3">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon6">
                  <path
                    d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path></svg
                ><span class="home-text07"
                  ><span
                    >Free, unlimited invoices, no credit card needed, no sign up
                    needed</span
                  ><br
                /></span>
              </div>
            </div>
          </div>
          <div class="home-image-container">
            <!-- <img
              alt="image"
              srcset="
                /hero-image-1200w.webp  1200w,
                /hero-image-tablet.webp  800w,
                /hero-image-mobile.webp  480w
              "
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/hero-image-1200w.webp"
              class="home-image2"
            /> -->

            <div class="image-slider-container">
              <div class="image-slider">
                <img
                  v-for="(image, index) in images"
                  :key="index"
                  :src="image"
                  class="slider-image"
                  :class="{ active: currentImageIndex === index }"
                  alt="Slider Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-section-one section-container">
        <div class="home-max-width1 max-content-container">
          <h2 class="home-text10">
            <span class="Heading2"
              >Manage all your invoices in <span v-html="rawikoa"></span></span
            ><span class="home-text12">one place</span
            ><span class="Heading2">
              and stay on top of <span v-html="raw1zk0"></span></span
            ><span class="home-text14 Heading2">your finances</span>
          </h2>
          <div class="home-cards-container">
            <feature-card
              text="Generate professional invoices effortlessly with our user-friendly interface. Customize templates to suit your brand and send them directly to clients."
              heading="Quick Invoice Creation"
              imageSrc="/quick-200w.webp"
            ></feature-card
            ><feature-card
              text="Easily access the complete edit history of your invoices. Track changes, review past versions, and maintain a detailed audit trail for your records."
              text1="See how &gt;"
              heading="View History"
              imageSrc="/history-200w.webp"
            ></feature-card
            ><feature-card
              text="Personalize your invoices by customizing the background to match your brand. Choose from a variety of templates or upload your own designs to enhance your professional image."
              text1="Learn more &gt;"
              heading="Customize Background"
              imageSrc="/custom-200w.webp"
            ></feature-card
            ><feature-card
              text="Send clean, professional invoices with no watermarks to maintain your brand's integrity. Ensure your communications are sleek and uniquely yours."
              text1="Learn more &gt;"
              heading="No Watermark"
              imageSrc="/brand-200w.webp"
            ></feature-card>
          </div>
        </div>
      </div>
      <div class="section-container">
        <div class="home-max-width2 max-content-container">
          <div class="home-image-container1">
            <div class="home-container1"></div>
          </div>
          <div class="home-content-container1">
            <h1 class="home-text15 Heading2">
              <span>Create order as easy as </span
              ><span class="home-text17">1-2-3.</span>
            </h1>
            <span class="home-text18 Content-Light"
              >Efficiently handle your invoicing with our user-friendly app.
              Create, send, and monitor invoices quickly, perfect for
              professionals seeking a simplified billing process.</span
            >
          </div>
        </div>
      </div>
      <div class="section-container">
        <div class="home-max-width3 max-content-container">
          <div class="home-content-container2">
            <h1 class="home-text19 Heading2">
              <span>Simple yet </span><span class="home-text21">Powerful.</span>
            </h1>
            <span class="home-text22 Content-Light"
              >Our invoice app is crafted based on the real-world experiences of
              my partner, addressing specific challenges we&apos;ve faced. We
              believe it offers practical solutions to your invoicing problems
              too. Simple to use yet robust, it streamlines your billing
              process, empowering you to focus more on what matters most in your
              business.</span
            >
          </div>
          <div class="home-image-container2">
            <img
              alt="image"
              srcset="
                /rolly-function.png  1200w,
                /rolly-function.png   800w,
                /rolly-function.png   480w
              "
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/r-feature-1200w.webp"
              class="home-image3"
            />
          </div>
        </div>
      </div>
      <div class="section-container">
        <div class="home-max-width4 max-content-container">
          <div class="home-heading-container">
            <h2 id="RollHere" class="home-text23 Heading2">
              <span>If you love simplicity, you’ll </span
              ><span>simply love <span v-html="rawiot2"></span></span
              ><span class="home-text26">Rolly.</span>
            </h2>
            <span class="Content-Light"
              >Go to App Store / Playstore, install Rolly - Custom Invoice
              Creator and start growing your business.</span
            >
          </div>
          <div class="home-get-the-app">
            <a
              href="https://apps.apple.com/gd/app/rolly-custom-invoice-creator/id6479781630?platform=iphone"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link2"
              ><img
                alt="image"
                src="/download/appstore-200h.webp"
                class="home-image4" /></a
            ><a
              href="https://play.google.com/store/apps/details?id=com.arcanetify.justinvoice"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link3"
              ><img
                alt="image"
                src="/download/googleplay-200h.webp"
                class="home-image5"
            /></a>
          </div>
          <!-- <div class="home-cards-container1"></div> -->
        </div>
      </div>
      <div class="home-section-six section-container">
        <div class="home-max-width5 max-content-container">
          <div class="home-content-container3">
            <h1 class="home-text28">
              <span class="home-text29">Get started today.</span
              ><span class="Heading2"> </span
              ><span class="home-text31 Heading2"
                >Try free for unlimited days.</span
              >
            </h1>
          </div>
          <div class="home-image-container3">
            <img
              alt="image"
              srcset="
                /bottom-hero-1200w.webp  1200w,
                /bottom-hero-tablet.webp  800w,
                /bottom-hero-mobile.webp  480w
              "
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/bottom-hero-1200w.webp"
              class="home-image6"
            />
          </div>
        </div>
      </div>
    </main>
    <app-footer></app-footer><app-copyright></app-copyright>
  </div>
</template>

<script>
import AppNavbar from '../components/navbar'
import FeatureCard from '../components/feature-card'
import AppFooter from '../components/footer'
import AppCopyright from '../components/copyright'

export default {
  name: 'Home',
  props: {},
  components: {
    AppNavbar,
    FeatureCard,
    AppFooter,
    AppCopyright,
  },
  data() {
    return {
      images: [
        'invoice-template-1.jpg',
        'invoice-template-2.jpg',
        'invoice-template-3.jpg',
        'invoice-template-4.jpg',
        'invoice-template-5.jpg',
      ],
      currentImageIndex: 0,
      intervalTime: 2000, 
    }
  },
  mounted() {
    this.startImageSlider();
  },
  methods: {
    startImageSlider() {
      setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
      }, this.intervalTime);
    },
  },
  metaInfo: {
    title: 'Rolly - Custom Invoice Creator',
    meta: [
      {
        name: 'description',
        content:
          'Custom Invoice App | Free Invoice Generator | Rolly helps you manage all the repetitive tasks while you can focus on your business',
      },
      {
        property: 'og:title',
        content: 'Rolly - Custom Invoice Creator',
      },
      {
        property: 'og:description',
        content:
          'Custom Invoice App | Free Invoice Generator | Rolly helps you manage all the repetitive tasks while you can focus on your business',
      }
    ],
  },
}
</script>

<style scoped>

.image-slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-slider {
  position: relative;
  width: 420px; /* A4 width */
  height: 594px; /* A4 height */
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.slider-image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.slider-image.active {
  opacity: 1;
}

.top-banner {
  width: 100%;
  background-color: var(--dl-color-backgrounds-primary);
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  animation: slideIn 1s ease-out, pulse 2s infinite;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 767px) {
  .top-banner {
    font-size: 14px;
    height: 60px;
    padding: 12px 0;
    animation: slideInMobile 1s ease-out, pulse 2s infinite;
  }
  
  @keyframes slideInMobile {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-backgrounds-gray);
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-max-width {
  align-items: stretch;
}
.home-content-container {
  flex: 1;
  display: flex;
  min-width: 50%;
  align-items: flex-start;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text {
  width: 90%;
  margin-bottom: 42px;
}
.home-text02 {
  color: var(--dl-color-backgrounds-primary);
}
.home-input-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.home-link1 {
  display: contents;
}
.home-image1 {
  width: 200px;
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-feature {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.home-icon {
  fill: #62bf77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text04 {
  color: var(--dl-color-grays-gray60);
}
.home-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.home-icon2 {
  fill: #62bf77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text05 {
  color: var(--dl-color-grays-gray60);
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.home-icon4 {
  fill: #62bf77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text06 {
  color: var(--dl-color-grays-gray60);
}
.home-feature3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon6 {
  fill: #62bf77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text07 {
  color: var(--dl-color-grays-gray60);
}
.home-image-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image2 {
  width: 100%;
  max-width: 589px;
  object-fit: cover;
  margin-left: 45px;
}
.home-section-one {
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-max-width1 {
  flex-direction: column;
}
.home-text10 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: 'Lexend';
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.home-text12 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text14 {
  color: var(--dl-color-backgrounds-primary);
  font-size: 42px;
  font-style: normal;
  font-family: 'Lexend';
  font-weight: 600;
  line-height: 1.3;
  text-transform: none;
  text-decoration: none;
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-max-width2 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-image-container1 {
  flex: 1;
  margin: auto;
  position: relative;
  align-self: center;
  align-content: center;
}
.home-container1 {
  width: 344px;
  height: 760px;
  display: flex;
  margin-left: 20%;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  background-image: url('/d23f2674-8970-4ab6-adf2-43e2909e2c3e1-400w.gif');
  background-position: center;
}
.home-content-container1 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text15 {
  margin-bottom: 24px;
}
.home-text17 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text18 {
  color: var(--dl-color-grays-gray100);
}
.home-max-width3 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-content-container2 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text19 {
  margin-bottom: 24px;
}
.home-text21 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text22 {
  color: var(--dl-color-grays-gray100);
}
.home-image-container2 {
  flex: 1;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.home-image3 {
  width: 100%;
  object-fit: cover;
  margin-left: 85px;
}
.home-max-width4 {
  flex-direction: column;
  justify-content: center;
}
.home-heading-container {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text23 {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text26 {
  color: var(--dl-color-backgrounds-primary);
}
.home-get-the-app {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
}
.home-link2 {
  display: contents;
}
.home-image4 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.home-link3 {
  display: contents;
}
.home-image5 {
  width: 200px;
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-cards-container1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.home-section-six {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  background-color: #292929;
}
.home-max-width5 {
  align-items: stretch;
}
.home-content-container3 {
  flex: 1;
  display: flex;
  min-width: 60%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text28 {
  font-size: 42px;
  font-style: normal;
  font-family: 'Lexend';
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.home-text29 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text31 {
  color: var(--dl-color-grays-white100);
}
.home-image-container3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image6 {
  width: 100%;
  max-width: 589px;
  min-width: 550px;
  object-fit: contain;
}
@media (max-width: 991px) {
  .home-max-width {
    flex-direction: column;
  }
  .home-content-container {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text {
    width: 100%;
  }
  .home-image2 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-container1 {
    margin-left: 15%;
  }
  .home-content-container1 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text15 {
    width: 100%;
  }
  .home-content-container2 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text19 {
    width: 100%;
  }
  .home-image3 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-max-width5 {
    flex-direction: column;
  }
  .home-text28 {
    width: 100%;
  }
  .home-image6 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .home-text10 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-image-container1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-container1 {
    margin-left: 25%;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-content-container1 {
    max-width: 100%;
  }
  .home-max-width3 {
    flex-direction: column-reverse;
  }
  .home-content-container2 {
    max-width: 100%;
  }
  .home-image-container2 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image3 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text23 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-cards-container1 {
    grid-template-columns: 1fr;
  }
  .home-image6 {
    min-width: 100%;
  }
}
@media (max-width: 479px) {
  .home-input-container {
    flex-direction: column;
  }
  .home-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image1 {
    margin-left: 0px;
  }
  .home-text04 {
    color: var(--dl-color-grays-gray60);
  }
  .home-text05 {
    color: var(--dl-color-grays-gray60);
  }
  .home-text06 {
    color: var(--dl-color-grays-gray60);
  }
  .home-text07 {
    color: var(--dl-color-grays-gray60);
  }
  .home-cards-container {
    justify-content: center;
  }
  .home-max-width2 {
    position: relative;
    align-items: center;
    justify-content: flex-start;
  }
  .home-image-container1 {
    margin: auto;
  }
  .home-container1 {
    width: 347px;
    margin-top: 0%;
    align-items: center;
    margin-left: var(--dl-space-space-oneandhalfunits);
    margin-right: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: center;
  }
  .home-image-container2 {
    margin-left: 0px;
  }
  .home-image3 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image4 {
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image5 {
    margin-left: 0px;
  }
  .home-text28 {
    font-size: 42px;
    font-style: normal;
    font-family: Lexend;
    font-weight: 600;
    line-height: 1.3;
    text-transform: none;
    text-decoration: none;
  }
  .home-text29 {
    color: var(--dl-color-backgrounds-primary);
  }
}
</style>
