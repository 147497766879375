<template>
  <footer class="footer-footer section-container"
    ><div class="footer-max-width max-content-container"
      ><div class="footer-container"
        ><img
          alt="image"
          src="/logo%20rolly-200h.webp"
          class="footer-image" /><span class="footer-text"
          ><span class="Content-Light">Keep your business rolling</span
          ><br /><br /><span>Feedback support@arcanetify.com</span
          ><br /></span></div
      ><div class="footer-container1"
        ><a
          href="https://www.buymeacoffee.com/9fl6vzw"
          target="_blank"
          rel="noreferrer noopener"
          class="footer-link"
          ><img :alt="imageAlt" :src="imageSrc" class="footer-image1" /></a
        ><a
          href="https://discord.gg/ztPSfQhKBu"
          target="_blank"
          rel="noreferrer noopener"
          class="footer-link1"
          ><img
            :alt="imageAlt1"
            :src="imageSrc1"
            class="footer-image2" /></a></div></div
  ></footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    text: {
      type: String,
      default: 'Link',
    },
    imageSrc1: {
      type: String,
      default: '/joindiscord-200h.webp',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
    imageAlt1: {
      type: String,
      default: 'image',
    },
    linkText: {
      type: String,
      default: 'https://example.com',
    },
    imageSrc: {
      type: String,
      default: '/bmc-button-200h.webp',
    },
    button: {
      type: String,
      default: 'Button',
    },
  },
}
</script>

<style scoped>
.footer-footer {
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-max-width {
  justify-content: space-between;
}
.footer-container {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-image {
  width: 106px;
  margin-bottom: var(--dl-space-space-twounits);
}
.footer-text {
  color: var(--dl-color-grays-gray100);
  max-width: 350px;
  margin-bottom: 60px;
}
.footer-container1 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-link {
  display: contents;
}
.footer-image1 {
  width: 200px;
  object-fit: cover;
  margin-right: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.footer-link1 {
  display: contents;
}
.footer-image2 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
@media(max-width: 991px) {
  .footer-max-width {
    flex-direction: column-reverse;
  }
  .footer-container {
    width: 100%;
  }
  .footer-container1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .footer-max-width {
    flex-direction: column;
  }
  .footer-container1 {
    flex-direction: column;
  }
  .footer-image1 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
</style>
