import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from './views/home'
import Status from './views/status'
import Order from './views/order'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'Status Payment',
      path: '/status/*',
      component: Status,
    },
    {
      name: 'Order',
      path: '/order/*',
      component: Order,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
    {
      name: 'ExplicitNotFound',
      path: '/notfound',
      component: NotFound,
    },

  ],
})
