<template>
  <div class="home-container">
    <nav class="navbar">
      <div class="navbar-content">
        <img :src="logoSrc" alt="Logo" class="navbar-logo" style="height:150px;" />
        <!-- <div class="navbar-business-details">
          <p class="business-name">ASD</p>
          <p class="business-address">ASD</p>
          <p class="business-phone">ASD</p>
        </div> -->
      </div>
    </nav>
    <main class="home-main">
    <div class="section-container">
      <div class="home-max-width max-content-container">
        <div class="home-content-container">
          <h1 class="home-text Heading2">
              
            <Timeline :events="events" style="width:100%"/>
            <!-- <span>Payment <span v-html="rawgi1d"></span></span>
            <span id="status" class="home-text02" v-text="statusMessage"></span> -->
          </h1>
          <div class="payment-confirmation-container">
            <h2 class="payment-confirmation-heading" v-text="paymentHeading"></h2>
            <p class="payment-confirmation-text" v-text="paymentText"></p>
            <div class="payment-details">
              <table class="payment-table">
                <tbody>
                  <tr>
                    <td>Invoice</td>
                    <td>{{ paymentDetails && paymentDetails.invoiceId ? paymentDetails.invoiceId : '' }}</td>
                  </tr>
                  <tr>
                    <td>Billing Address</td>
                    <td>{{ billingAddress ? formatAddress(billingAddress) : '' }}</td>
                  </tr>
                  <tr>
                    <td>Shipping Address</td>
                    <td>{{ shippingAddress ? formatAddress(shippingAddress) : '' }}</td>
                  </tr>
                  <tr>
                    <td>Subtotal</td>
                    <td>{{ formatCurrency(subtotal, currency)}}</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>{{ formatCurrency(total, currency)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- v-if="linkStatus === 'valid'" -->
          
<div class="button-container">
  <a :href="invoiceLink"  @click="downloadClick" class="navbar-primary-btn1 button-primary button">
    <font-awesome-icon icon="file-pdf" /> {{ downloadText }}
  </a>

  <a v-if="isPaymentVisible" :href="paymentLink" @click="downloadClick" class="navbar-primary-btn1 button-primary button">
    <font-awesome-icon icon="link" /> Payment Link
  </a>
</div>

          <!-- <div class="button-container">
  <a :href="invoiceLink" class="navbar-primary-btn1 button-primary button">
    <font-awesome-icon icon="link" /> Download Receipt
  </a>
  <a :href="invoiceLink" class="navbar-primary-btn1 button-primary button">
    <font-awesome-icon icon="link" /> Download Receipt
  </a>
</div> -->
          
        </div>
      </div>
    </div>
  </main>

<span class="footer-text">Powered by <a href="https://rolly.arcanetify.com" class="footer-link">Rolly</a></span>

    <!-- <app-footer></app-footer>
    <app-copyright></app-copyright> -->

  </div>
</template>

<script>
import AppNavbar from '../components/navbar'
import FeatureCard from '../components/feature-card'
import AppFooter from '../components/footer'
import AppCopyright from '../components/copyright'
import Timeline from '../components/Timeline.vue';


export default {
  name: 'Home',
  props: {},
  components: {
    AppNavbar,
    FeatureCard,
    AppFooter,
    AppCopyright,
    Timeline,
    
  },

  data() {
    return {
      currency : '',
      subtotal : '',
      total : '',
      paymentDetails: {},
      billingAddress : {},
      shippingAddress : {},
      items : [],
      statusMessage: '',
      paymentHeading: '',
      paymentText: '',
      invoiceLink : '',
      paymentLink : '',
      logoSrc : '',
      srcImg : '',
      linkStatus : '',
      businessId : '',
      events: [],
      isPaymentVisible : false,
      currencies : [
        { code: 'AUD', symbol: '$', format: '#,##0.00', locale: 'en-AU' },
        { code: 'IDR', symbol: 'Rp', format: '#,##0', locale: 'id-ID' },
        { code: 'USD', symbol: '$', format: '#,##0.00', locale: 'en-US' },
        { code: 'CNY', symbol: '¥', format: '#,##0.00', locale: 'zh-CN' },
        { code: 'JPY', symbol: '¥', format: '#,##0', locale: 'ja-JP' },
        { code: 'MYR', symbol: 'RM', format: '#,##0.00', locale: 'ms-MY' },
        { code: 'EUR', symbol: '€', format: '#,##0.00', locale: 'de-DE' },
        { code: 'GBP', symbol: '£', format: '#,##0.00', locale: 'en-GB' },
        { code: 'INR', symbol: '₹', format: '#,##0.00', locale: 'en-IN' },
        { code: 'CAD', symbol: 'C$', format: '#,##0.00', locale: 'en-CA' },
        { code: 'NZD', symbol: 'NZ$', format: '#,##0.00', locale: 'en-NZ' },
        { code: 'CHF', symbol: 'CHF', format: '#,##0.00', locale: 'de-CH' },
        { code: 'ZAR', symbol: 'R', format: '#,##0.00', locale: 'en-ZA' },
        { code: 'MXN', symbol: '$', format: '#,##0.00', locale: 'es-MX' },
        { code: 'BRL', symbol: 'R$', format: '#,##0.00', locale: 'pt-BR' },
        { code: 'SGD', symbol: 'S$', format: '#,##0.00', locale: 'en-SG' },
        { code: 'HKD', symbol: 'HK$', format: '#,##0.00', locale: 'en-HK' },
        { code: 'RUB', symbol: '₽', format: '#,##0.00', locale: 'ru-RU' },
        { code: 'SEK', symbol: 'kr', format: '#,##0.00', locale: 'sv-SE' },
        { code: 'NOK', symbol: 'kr', format: '#,##0.00', locale: 'no-NO' },
        { code: 'TRY', symbol: '₺', format: '#,##0.00', locale: 'tr-TR' },
        { code: 'KRW', symbol: '₩', format: '#,##0', locale: 'ko-KR' },
        { code: 'THB', symbol: '฿', format: '#,##0.00', locale: 'th-TH' },
        { code: 'PHP', symbol: '₱', format: '#,##0.00', locale: 'en-PH' },
        { code: 'AED', symbol: 'د.إ', format: '#,##0.00', locale: 'ar-AE' },
        { code: 'SAR', symbol: 'ر.س', format: '#,##0.00', locale: 'ar-SA' },
        { code: 'QAR', symbol: 'ر.ق', format: '#,##0.00', locale: 'ar-QA' },
      ]
      
    }
  },
  metaInfo: {
    title: 'Order Status',
    meta: [
      {
        name: 'description',
        content:
          'Custom Invoice App | Free Invoice Generator | Rolly helps you manage all the repetitive tasks while you can focus on your business',
      },
      {
        property: 'og:title',
        content: 'Rolly - Custom Invoice Creator',
      },
      {
        property: 'og:description',
        content:
          'Custom Invoice App | Free Invoice Generator | Rolly helps you manage all the repetitive tasks while you can focus on your business',
      },
    ],
  },
  created() {
    this.fetchPaymentDetails()
  },
  computed:{
    downloadText() {
      return this.paymentDetails.status === 'pending' ? 'Download Invoice' : 'Download Receipt';
    }
  },

methods: {
  getURLSegment() {
    const pathArray = window.location.pathname.split('/');
    const segment = pathArray.pop() || pathArray.pop();  // Handle trailing slash
    return segment;
  },
  async checkLink() {
      this.linkStatus = 'loading';
      try {
        const response = await fetch(this.invoiceLink, { method: 'HEAD' });
        if (response.ok) {
          this.linkStatus = 'valid';
        } else {
          this.linkStatus = 'invalid';
        }
      } catch (error) {
        this.linkStatus = 'invalid';
      }
    },
  fetchPaymentDetails() {
    const segment = this.getURLSegment();
    //fetch(`http://localhost:8010/proxy/rolly/success/${segment}`)  // Using template literals to insert the segment
    fetch(`https://integration.arcanetify.com/rolly/success/${segment}`)  // Using template literals to insert the segment
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    this.paymentDetails = data;
    console.log(this.paymentDetails);

    if (this.paymentDetails.status === "pending") {
      this.statusMessage = "Pending";
      this.paymentHeading = "Waiting for your payment";

      if(this.paymentDetails.paymentLink != null){
        this.isPaymentVisible = true;
        this.paymentLink = this.paymentDetails.paymentLink;
      }

      this.paymentText = "Please refresh this page if you have paid the invoice";
      this.srcImg = "/payment_pending.png";
            this.events = [
        { date: '2024-01-01', status: 'Order Created' , active: true},
        { date: '2024-01-02', status: 'Quotation', active: true },
        { date: '2024-01-02', status: 'Invoice', active: true },
        { date: '2024-01-03', status: 'Paid' , active: false},
      ];
      
    } else {
      this.statusMessage = "Successful";
      this.paymentHeading = "Thank you for your payment! 🧡";
      this.srcImg = "/payment-successful.png";
      this.events = [
        { date: '2024-01-01', status: 'Order Created' , active: true},
        { date: '2024-01-02', status: 'Quotation', active: true },
        { date: '2024-01-02', status: 'Invoice', active: true },
        { date: '2024-01-03', status: 'Paid' , active: true},
      ];
    }


    const additionalData = JSON.parse(data.additionalData);
    this.billingAddress = additionalData.billing_address;
    this.shippingAddress = additionalData.shipping_address;
    this.items = additionalData.items;
    this.logoSrc = data.logo;
    this.businessId = data.businessId;
    this.currency = data.currency;
    this.subtotal = data.subtotal;
    this.total =  data.total;
    this.invoiceLink = "https://rolly.arcanetify.com/invoice/"+data.businessId+"/"+segment+".pdf" ;
    this.checkLink();
  })
  .catch((error) => {
    console.error('Error fetching payment details:', error);
  });
  },
  
    downloadClick() {
      console.log("downloadClick function called");
      const segment = this.getURLSegment();
      const payload = {
        businessId: this.businessId,
        notif: segment,
        status: this.paymentDetails.status
      };

      console.log("Payload: ", payload);
      // this.$axios.post('http://localhost:8010/proxy/invoicelink/downloadClick', payload)
      this.$axios.post('https://integration.arcanetify.com/invoicelink/downloadClick', payload)
        .then(response => {
          console.log("Response data: ", response.data);
        })
        .catch(error => {
          console.error('Error fetching payment details:', error);
        });
    },

  
formatAddress(address) {
  let formattedAddress = `${address.first_name} ${address.last_name}`;

  if (address.address && address.address.trim() && address.address !== ', ') {
    formattedAddress += `, ${address.address.trim()}`;
  }
  if (address.city && address.city.trim()) {
    formattedAddress += `, ${address.city.trim()}`;
  }
  if (address.state && address.state.trim()) {
    formattedAddress += `, ${address.state.trim()}`;
  }
  if (address.postal_code && address.postal_code.trim()) {
    formattedAddress += `, ${address.postal_code.trim()}`;
  }
  if (address.country_code && address.country_code.trim()) {
    formattedAddress += `, ${address.country_code.trim()}`;
  }

  // Remove any trailing commas or spaces
  formattedAddress = formattedAddress.replace(/, $/, '');

  return formattedAddress;
},

formatCurrency(value, currencyCode) {
  const currency = this.currencies.find(curr => curr.code === currencyCode);
  if (!currency) {
    throw new Error('Unsupported currency code');
  }

  return new Intl.NumberFormat(currency.locale, {
    style: 'currency',
    currency: currency.code,
    minimumFractionDigits: currency.format.includes('.00') ? 2 : 0,
    maximumFractionDigits: currency.format.includes('.00') ? 2 : 0,
  }).format(value);
}

},

}
</script>

<style scoped>

.navbar-primary-btn1.button-primary.button {
  align-items: center;
}

.font-awesome-icon {
  margin-right: 8px; 
}


.navbar-logo{
margin-top : 40px;
}

.button-container {
  display: flex;
  gap: 10px; 
}

@media (max-width: 1024px) {
.navbar-primary-btn1.button-primary.button {
  width:  100%;
}


  .button-container {
    display: block;
    width: 100%;
  }

  .button-container > * {
    margin-bottom: 20px; /* Adjust the value as needed */
  }

  .button-container > *:last-child {
    margin-bottom: 0; /* Remove margin for the last element */
  }
}

.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-backgrounds-gray);
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-max-width {
  align-items: stretch;
}
.home-content-container {
  flex: 1;
  align-items: center;
  display: flex;
  min-width: 50%;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text {
  width: 100%;
  margin-bottom: 10px;
}
.home-text02 {
  color: var(--dl-color-backgrounds-primary);
}


@media (max-width: 500px) {
  .home-text{font-size: 30px;}
}

.home-input-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.home-link1 {
  display: contents;
}
.home-image1 {
  width: 200px;
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-feature {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.home-icon {
  fill: #62bf77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text04 {
  color: var(--dl-color-grays-gray60);
}
.home-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.home-icon2 {
  fill: #62bf77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text05 {
  color: var(--dl-color-grays-gray60);
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.home-icon4 {
  fill: #62bf77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text06 {
  color: var(--dl-color-grays-gray60);
}
.home-feature3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon6 {
  fill: #62bf77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text07 {
  color: var(--dl-color-grays-gray60);
}
.home-image-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image2 {
  width: 100%;
  max-width: 589px;
  object-fit: cover;
  margin-left: 45px;
}
.home-section-one {
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-max-width1 {
  flex-direction: column;
}
.home-text10 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: 'Lexend';
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.home-text12 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text14 {
  color: var(--dl-color-backgrounds-primary);
  font-size: 42px;
  font-style: normal;
  font-family: 'Lexend';
  font-weight: 600;
  line-height: 1.3;
  text-transform: none;
  text-decoration: none;
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-max-width2 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-image-container1 {
  flex: 1;
  margin: auto;
  position: relative;
  align-self: center;
  align-content: center;
}
.home-container1 {
  width: 344px;
  height: 760px;
  display: flex;
  margin-left: 20%;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  background-image: url('/d23f2674-8970-4ab6-adf2-43e2909e2c3e1-400w.gif');
  background-position: center;
}
.home-content-container1 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text15 {
  margin-bottom: 24px;
}
.home-text17 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text18 {
  color: var(--dl-color-grays-gray100);
}
.home-max-width3 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-content-container2 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text19 {
  margin-bottom: 24px;
}
.home-text21 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text22 {
  color: var(--dl-color-grays-gray100);
}
.home-image-container2 {
  flex: 1;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.home-image3 {
  width: 100%;
  object-fit: cover;
  margin-left: 85px;
}
.home-max-width4 {
  flex-direction: column;
  justify-content: center;
}
.home-heading-container {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text23 {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text26 {
  color: var(--dl-color-backgrounds-primary);
}
.home-get-the-app {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
}
.home-link2 {
  display: contents;
}
.home-image4 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.home-link3 {
  display: contents;
}
.home-image5 {
  width: 200px;
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-cards-container1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.home-section-six {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  background-color: #292929;
}
.home-max-width5 {
  align-items: stretch;
}
.home-content-container3 {
  flex: 1;
  display: flex;
  min-width: 60%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text28 {
  font-size: 42px;
  font-style: normal;
  font-family: 'Lexend';
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.home-text29 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text31 {
  color: var(--dl-color-grays-white100);
}
.home-image-container3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image6 {
  width: 100%;
  max-width: 589px;
  min-width: 550px;
  object-fit: contain;
}
@media (max-width: 991px) {
  .home-max-width {
    flex-direction: column;
  }
  .home-content-container {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text {
    width: 100%;
  }
  .home-image2 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-container1 {
    margin-left: 15%;
  }
  .home-content-container1 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text15 {
    width: 100%;
  }
  .home-content-container2 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text19 {
    width: 100%;
  }
  .home-image3 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-max-width5 {
    flex-direction: column;
  }
  .home-text28 {
    width: 100%;
  }
  .home-image6 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .home-text10 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-image-container1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-container1 {
    margin-left: 25%;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-content-container1 {
    max-width: 100%;
  }
  .home-max-width3 {
    flex-direction: column-reverse;
  }
  .home-content-container2 {
    max-width: 100%;
  }
  .home-image-container2 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image3 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text23 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-cards-container1 {
    grid-template-columns: 1fr;
  }
  .home-image6 {
    min-width: 100%;
  }
}
@media (max-width: 479px) {
  .home-input-container {
    flex-direction: column;
  }
  .home-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image1 {
    margin-left: 0px;
  }
  .home-text04 {
    color: var(--dl-color-grays-gray60);
  }
  .home-text05 {
    color: var(--dl-color-grays-gray60);
  }
  .home-text06 {
    color: var(--dl-color-grays-gray60);
  }
  .home-text07 {
    color: var(--dl-color-grays-gray60);
  }
  .home-cards-container {
    justify-content: center;
  }
  .home-max-width2 {
    position: relative;
    align-items: center;
    justify-content: flex-start;
  }
  .home-image-container1 {
    margin: auto;
  }
  .home-container1 {
    width: 347px;
    margin-top: 0%;
    align-items: center;
    margin-left: var(--dl-space-space-oneandhalfunits);
    margin-right: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: center;
  }
  .home-image-container2 {
    margin-left: 0px;
  }
  .home-image3 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image4 {
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image5 {
    margin-left: 0px;
  }
  .home-text28 {
    font-size: 42px;
    font-style: normal;
    font-family: Lexend;
    font-weight: 600;
    line-height: 1.3;
    text-transform: none;
    text-decoration: none;
  }
  .home-text29 {
    color: var(--dl-color-backgrounds-primary);
  }
}

.payment-confirmation-container {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #e0e0e0; /* Subtle border around the receipt */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Light shadow for a subtle lift effect */
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .payment-confirmation-container {
    padding: 20px 5px; /* Reduced padding on smaller screens */
    border-radius: 4px; /* Smaller radius for a less bulky look on small devices */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* Lighter shadow to reduce visual heaviness */
  }
}

.payment-confirmation-heading {
  font-size: 18px; /* Slightly larger for emphasis */
  color: #333; /* Keeping it dark for readability */
  text-align: center;
  padding-bottom: 5px; /* Space below the heading */
}

.payment-confirmation-text {
  text-align: center;
  color: #666;
  font-size: 14px; /* Smaller for less emphasis */
  margin-top: 10px; /* Spacing from the header */
  margin-bottom: 20px; /* Spacing before the table */
}

.payment-table {
  width: 100%; /* Utilizing full container width */
  border-collapse: collapse;
  font-size: 14px; /* Smaller font for table text */
  table-layout: fixed; /* Ensures table layout does not overflow the container */
}

.payment-table th,
.payment-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd; /* Light grey line for separation */
  word-wrap: break-word; /* Allows text to wrap onto the next line */
}

@media (max-width: 768px) {
  .payment-table th,
  .payment-table td {
    padding: 8px 5px; /* Reduced padding on smaller screens */
    font-size: 12px; /* Smaller font size for better readability on mobile */
  }
}

.payment-table th {
  background-color: #f0f0f0; /* Light grey for header, giving a subtle highlight */
  text-transform: uppercase; /* Stylistic choice to make headers more formal */
  font-weight: bold; /* Emphasizing the header */
}

.payment-table tbody tr:last-child td {
  border-bottom: none; /* Removing the bottom border from the last row */
}

.footer-link {
    color: orange; /* Sets the link color to orange */
    text-decoration: none; /* Removes the underline from the link */
}

.footer-link:hover {
    text-decoration: underline; /* Adds underline on hover for better user interaction */
}


.items-container {
  margin: 20px auto;
  width: 90%; /* Adjust width based on layout requirements */
  background-color: #f9f9f9; /* Light background for the items area */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.items-heading {
  text-align: center;
  padding: 10px;
  font-size: 20px; /* Larger font size for heading */
  color: #333;
  background-color: #e2e2e2; /* Slightly darker background for the header */
}

.items-table {
  width: 100%; /* Full width of the container */
  border-collapse: collapse; /* Ensures borders are merged */
  margin: 10px 0;
}

.items-table th, .items-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd; /* Separates rows subtly */
}

.items-table th {
  background-color: #ddd; /* Light grey background for table headers */
  color: #333;
}

.items-table tbody tr:last-child td {
  border-bottom: none; /* Avoids double border at the bottom */
}

.footer-text{
  margin-bottom:  80px;
}


</style>

