<template>
  <div class="timeline">
    <div v-for="(event, index) in events" :key="index" class="timeline-event">
      <div :class="['timeline-dot', event.active ? 'active' : 'inactive']"></div>
      <div class="timeline-content">
        <p class="timeline-status">{{ event.status }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timeline',
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.timeline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 30px 0;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 2px;
  background: #ccc;
}

.timeline-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.timeline-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -40px;
}

.timeline-dot.active {
  background: orange;
  animation: glow 1.5s infinite;
}

.timeline-dot.inactive {
  background: grey;
}

.timeline-content {
  text-align: center;
  font-size: 14px;
  color: orange;
}

.timeline-date {
  margin-bottom: 5px;
}

.timeline-status {
  margin: 0;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px orange;
  }
  50% {
    box-shadow: 0 0 20px orange, 0 0 30px orange, 0 0 40px orange, 0 0 50px orange;
  }
  100% {
    box-shadow: 0 0 5px orange;
  }
}
</style>
