import Vue from 'vue';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Ensure you import the specific icons you need
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLink); // Add faLink or any other icons you might use
library.add(faFilePdf); // Add faLink or any other icons you might use

Vue.component('font-awesome-icon', FontAwesomeIcon);

import App from './App.vue';
import router from './router';

// Set axios as a prototype property to make it available globally
Vue.prototype.$axios = axios;

// Set fetch as a prototype property to make it available globally
Vue.prototype.$http = window.fetch;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
